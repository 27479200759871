import { extendBaseTheme, theme as chakraTheme } from '@chakra-ui/react'

import { Switch } from '@/styles/components/Switch.ts'
import { Tabs } from '@/styles/components/Tabs.ts'

export const theme = extendBaseTheme({
  components: {
    ...chakraTheme.components,
    Tabs,
    Switch,
  },
  sizes: {
    maxW: '1244px',
  },
  colors: {
    black: '#000000',
    white: '#ffffff',
    danger: '#EB2F45',
    neutrals: {
      1: '#FCFCFD',
      2: '#F4F5F6',
      3: '#E6E8EC',
      4: '#B1B5C3',
      5: '#B1B5C3',
      6: '#353945',
      8: '#141414',
      9: '#05050A',
    },
    primary: {
      3: '#EB2F45',
      4: '#45B26B',
    },
    secondary: {
      1: '#9BD6FF',
      2: '#E4D7CF',
      3: '#FFD166',
      4: '#CDB4DB',
    },
  },
  shadows: {
    card: '0px 5px 10px 0px rgba(0, 0, 0, 0.05), 0px 15px 30px 0px rgba(0, 0, 0, 0.05), 0px 20px 40px 0px rgba(0, 0, 0, 0.05)',
  },
  fonts: {
    input:
      'Inter, SFRounded, ui-rounded, "SF Pro Rounded", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  styles: {
    global: () => {
      return {
        body: {
          fontFamily:
            'Inter, SFRounded, ui-rounded, "SF Pro Rounded", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          backgroundColor: 'neutrals.0',
          minHeight: '100svh',
        },
      }
    },
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: true,
  },
})
