import {
  Box,
  BoxProps,
  Button,
  Fade,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import {
  ArrowDownTrayIcon,
  ArrowLongUpIcon,
  CheckCircleIcon,
  DocumentDuplicateIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'
import { useVirtualizer } from '@tanstack/react-virtual'
import classnames from 'classnames'
import { debounce } from 'lodash-es'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useCopyToClipboard } from 'react-use'

import classes from '@/components/DataViewer/index.module.scss'
import { TextOverflowTooltip } from '@/components/TextOverflowTooltip'
import { useSettings } from '@/contexts/Settings'
import { downloadBlob } from '@/helpers/downloadBlob'
import { useSelected } from '@/store/useSelected'

interface Props extends BoxProps {
  intersection: Set<string>
  name: string
  codes: string[]
  description?: string
  disableHighlight?: boolean
}

export const FileViewer = memo(function FileViewer({
  name,
  codes,
  intersection,
  description,
  disableHighlight,
  ...rest
}: Props) {
  const [keyword, setKeyword] = useState('')
  const [showInput, setShowInput] = useState(false)
  const { disableInputTip, setDisableInputTip } = useSettings()
  const filteredCodes = useMemo(() => {
    if (!keyword || keyword.match(/^\*+$/)) return codes
    if (!keyword.includes('*')) return codes.filter((code) => code.includes(keyword))
    const raw = keyword.replace(/\*/g, '.')
    const re = new RegExp(raw)
    return codes.filter((code) => re.test(code))
  }, [codes, keyword])

  const [, copyToClipboard] = useCopyToClipboard()
  const toast = useToast()
  const parentRef = useRef<HTMLDivElement>(null)
  const virtualizer = useVirtualizer({
    count: filteredCodes.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 24,
  })

  const [showUpButton, setShowUpButton] = useState(false)

  const handleScroll = useMemo(() => {
    return debounce((e: React.UIEvent<HTMLDivElement>) => {
      if (!e.target) return
      setShowUpButton((e.target as HTMLDivElement).scrollTop > 50)
    }, 200)
  }, [])

  const { selected, lastSelected, toggleSelected, hovering, setHovering, clearHovering } = useSelected()

  useEffect(() => {
    if (!lastSelected || lastSelected.name === name) return
    const index = filteredCodes.indexOf(lastSelected.code)
    virtualizer.scrollToIndex(index, { align: 'center', behavior: 'smooth' })
  }, [filteredCodes, lastSelected, name, virtualizer])

  return (
    <Box display="flex" overflow="auto" flexDir="column" pos="relative" {...rest}>
      <Heading
        as="h3"
        flexShrink={0}
        alignItems="center"
        p={2}
        display="flex"
        gap={1}
        borderBottom="1px solid"
        borderBottomColor="neutrals.3"
      >
        <Flex fontSize={15} mr="auto" whiteSpace="nowrap" alignItems="center" overflow="auto" py={1} gap={1}>
          {description ? (
            <Tooltip label={description} placement="top" hasArrow>
              <InformationCircleIcon width={14} height={14} style={{ flexShrink: 0 }} />
            </Tooltip>
          ) : null}
          <TextOverflowTooltip label={name} placement="top" hasArrow>
            <Text whiteSpace="nowrap" overflow="hidden" flexGrow={1} textOverflow="ellipsis">
              {name}
            </Text>
          </TextOverflowTooltip>
          <Text flexShrink={0} fontWeight="normal">
            ({codes.length === filteredCodes.length ? codes.length : `${filteredCodes.length}/${codes.length}`})
          </Text>
        </Flex>
        {showInput ? (
          <InputGroup width={120}>
            <Popover autoFocus={false} placement="top" trigger="hover">
              <PopoverTrigger>
                <Input
                  size="sm"
                  autoFocus
                  rounded={8}
                  placeholder="搜索..."
                  value={keyword}
                  outlineColor={keyword ? 'danger' : undefined}
                  maxLength={4}
                  pattern="[0-9*]{0,4}"
                  onChange={(e) => {
                    const trimmed = e.currentTarget.value.trim()
                    setKeyword(trimmed)
                  }}
                />
              </PopoverTrigger>
              <Portal>
                {!disableInputTip ? (
                  <PopoverContent>
                    <PopoverHeader fontSize={14}>提示</PopoverHeader>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <Box padding={3}>
                      <Text fontSize="14px">
                        <strong>**12</strong> 可匹配 00<strong>12</strong>、11<strong>12</strong> 等
                      </Text>
                    </Box>
                    <PopoverFooter display="flex" justifyContent="flex-end">
                      <Button
                        size="sm"
                        colorScheme="yellow"
                        onClick={() => {
                          setDisableInputTip(true)
                        }}
                      >
                        不再提示
                      </Button>
                    </PopoverFooter>
                  </PopoverContent>
                ) : null}
              </Portal>
            </Popover>
            <InputRightElement height="32px">
              <IconButton
                aria-label="清除"
                size="xs"
                icon={<XMarkIcon width={14} height={14} />}
                colorScheme="red"
                onClick={() => {
                  setShowInput(false)
                  setKeyword('')
                }}
              />
            </InputRightElement>
          </InputGroup>
        ) : (
          <IconButton
            flexShrink={0}
            aria-label="过滤"
            size="sm"
            icon={<MagnifyingGlassIcon width={18} height={18} />}
            onClick={() => {
              setShowInput(true)
            }}
          />
        )}
        <IconButton
          aria-label="复制"
          size="sm"
          icon={<DocumentDuplicateIcon width={16} height={16} />}
          onClick={() => {
            copyToClipboard(filteredCodes.join('\n'))
            toast({
              title: '已复制',
              status: 'success',
            })
          }}
        />
        <IconButton
          aria-label="下载"
          size="sm"
          icon={<ArrowDownTrayIcon width={16} height={16} />}
          onClick={() => {
            const blob = new Blob([codes.join(' ')])
            downloadBlob(blob, name)
          }}
        />
      </Heading>
      <Box overflow="auto" flexGrow={1} ref={parentRef} onScroll={handleScroll} style={{ scrollbarWidth: 'thin' }}>
        <div
          onDoubleClick={(e) => e.preventDefault()}
          style={{
            height: `${virtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {virtualizer.getVirtualItems().map(function (item) {
            const code = filteredCodes[item.index]
            return (
              <div
                key={item.key}
                className={classnames(
                  intersection.has(code) && !disableHighlight ? classes.highlight : '',
                  hovering === code ? classes.focus : '',
                  selected.includes(code) ? classes.selected : '',
                  classes.code,
                )}
                style={{
                  height: `${item.size}px`,
                  transform: `translateY(${item.start}px)`,
                }}
                onMouseEnter={() => setHovering(code)}
                onMouseLeave={clearHovering}
                onClick={() => toggleSelected(code, name)}
              >
                {code}
                {selected.includes(code) ? <CheckCircleIcon className={classes.icon} width={16} height={16} /> : null}
              </div>
            )
          })}
        </div>
      </Box>
      <Fade in={showUpButton}>
        <Tooltip label="回到顶部" hasArrow>
          <IconButton
            pos="absolute"
            right={4}
            bottom={1}
            aria-label="回到顶部"
            size="sm"
            icon={<ArrowLongUpIcon width={16} height={16} />}
            onClick={() => {
              virtualizer.scrollToOffset(0, { behavior: 'smooth' })
            }}
          />
        </Tooltip>
      </Fade>
    </Box>
  )
})
