import { Checkbox, Flex, ModalProps } from '@chakra-ui/react'

import { useSettings } from '@/contexts/Settings'
import { BaseModal } from '@/modals/BaseModal'
import { createUITaskManager } from '@/modals/UITaskManager'

interface Props extends ModalProps {}

export function SettingsModal(props: Props) {
  const { disableUnionColumn, setDisableUnionColumn, disableInputTip, setDisableInputTip } = useSettings()
  return (
    <BaseModal title="设置" width={500} {...props}>
      <Flex flexDir="column" gap={1}>
        <Checkbox
          isChecked={!disableUnionColumn}
          onChange={(e) => {
            setDisableUnionColumn(!e.currentTarget.checked)
          }}
        >
          显示并集
        </Checkbox>
        <Checkbox
          isChecked={!disableInputTip}
          onChange={(e) => {
            setDisableInputTip(!e.currentTarget.checked)
          }}
        >
          显示提示
        </Checkbox>
      </Flex>
    </BaseModal>
  )
}

export const { ui: settingsModalUi, controller: settingsModal } = createUITaskManager(SettingsModal)
