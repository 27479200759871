import { uniq } from 'lodash-es'
import { create } from 'zustand'

interface State {
  selected: string[]
  hovering: string
  lastSelected: {
    name: string
    code: string
  }

  setSelected(code: string, name: string): void
  unsetSelected(code: string): void
  toggleSelected(code: string, name: string): void
  clearSelected(): void
  setHovering(code: string): void
  clearHovering(): void
}

export const useSelected = create<State>((set) => ({
  selected: [],
  lastSelected: { name: '', code: '' },
  setSelected: (code: string, name: string) => {
    return set((state) => ({
      selected: uniq([...state.selected, code]),
      lastSelected: { name, code },
    }))
  },
  unsetSelected: (code) => {
    return set((state) => ({ selected: state.selected.filter((x) => x !== code) }))
  },
  toggleSelected: (code, name) => {
    return set((state) => {
      if (state.selected.includes(code)) {
        return { selected: state.selected.filter((x) => x !== code) }
      }
      return { selected: uniq([...state.selected, code]), lastSelected: { name, code } }
    })
  },
  clearSelected: () => {
    return set({ selected: [] })
  },
  hovering: '',
  setHovering: (code: string) => {
    return set({ hovering: code })
  },
  clearHovering: () => {
    return set({ hovering: '' })
  },
}))
