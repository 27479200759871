import { ChakraBaseProvider, createLocalStorageManager, ToastProviderProps } from '@chakra-ui/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRouter, RouterProvider } from '@tanstack/react-router'
import { StrictMode } from 'react'

import { queryClient } from '@/configs/queryClient.ts'
import { SettingsProvider } from '@/contexts/Settings'
import { routeTree } from '@/routeTree.gen'
import { theme } from '@/styles/theme.ts'

const router = createRouter({ routeTree })

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

const colorModeManager = createLocalStorageManager('file-compare-color-mode')
const toastOptions = {
  defaultOptions: { position: 'top-right' },
} satisfies ToastProviderProps
export function App() {
  return (
    <StrictMode>
      <ChakraBaseProvider theme={theme} toastOptions={toastOptions} colorModeManager={colorModeManager}>
        <QueryClientProvider client={queryClient}>
          <SettingsProvider>
            <RouterProvider router={router} />
          </SettingsProvider>
          <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
        </QueryClientProvider>
      </ChakraBaseProvider>
    </StrictMode>
  )
}
