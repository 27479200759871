import { Box, BoxProps } from '@chakra-ui/react'
import { CursorArrowRippleIcon } from '@heroicons/react/24/solid'
import classnames from 'classnames'
import { memo, useEffect, useRef, useState } from 'react'

import styles from '@/components/DropArea/index.module.scss'

interface Props extends BoxProps {
  onDropFiles?(fileList: FileList): void
  message?: string
}

export const DropArea = memo(function DropArea({
  className,
  onDropFiles,
  message = 'Drop here.',
  children,
  ...rest
}: Props) {
  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const dropRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const dropEle = dropRef.current
    if (!dropEle) return
    const dragenter = () => {
      setIsDraggingOver(true)
    }
    const dragover = (event: HTMLElementEventMap['drop']) => {
      event.preventDefault()
      setIsDraggingOver(true)
    }
    const dragleave = () => {
      setIsDraggingOver(false)
    }
    const drop = (event: HTMLElementEventMap['drop']) => {
      event.preventDefault()
      event.stopPropagation()
      if (event.dataTransfer?.files.length) {
        onDropFiles?.(event.dataTransfer.files)
      }
      setIsDraggingOver(false)
    }
    dropEle?.addEventListener('dragenter', dragenter)
    dropEle?.addEventListener('dragover', dragover)
    dropEle?.addEventListener('dragleave', dragleave)
    dropEle?.addEventListener('drop', drop)

    return () => {
      if (!dropEle) return
      dropEle.removeEventListener('dragenter', dragenter)
      dropEle.removeEventListener('dragover', dragover)
      dropEle.removeEventListener('dragleave', dragleave)
    }
  }, [onDropFiles])

  return (
    <Box
      as="div"
      className={classnames(className, styles.drop, isDraggingOver ? styles.dragover : null)}
      {...rest}
      ref={dropRef}
    >
      {children}
      <div className={styles.overlay}>
        <CursorArrowRippleIcon width={60} height={60} />
        <div className={styles.message}>{message}</div>
      </div>
    </Box>
  )
})
