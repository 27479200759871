import { Box, BoxProps, Button, Center, Flex, Spinner, Text } from '@chakra-ui/react'
import { DocumentArrowUpIcon } from '@heroicons/react/24/outline'
import { useCallback, useState, useTransition } from 'react'
import { useFilePicker } from 'use-file-picker'

import { FilesViewer } from '@/components/DataViewer/FilesViewer'
import { readText } from '@/components/DataViewer/readText'
import { FileRecord } from '@/components/DataViewer/types'
import { DropArea } from '@/components/DropArea'

export function DataViewer(props: BoxProps) {
  const [records, setRecords] = useState<FileRecord[]>(() => [])
  const [pending, startTransition] = useTransition()

  const handleSelectFiles = useCallback(async (files: FileList) => {
    const list = [...files]
    const results: FileRecord[] = await Promise.all(
      list.map(async (file) => {
        const content = await readText(file)
        return {
          content,
          name: file.name,
        } satisfies FileRecord
      }),
    )
    startTransition(() => {
      setRecords(results)
    })
  }, [])

  const { openFilePicker } = useFilePicker({
    multiple: true,
    onFilesSelected: ({ plainFiles }) => {
      handleSelectFiles(plainFiles)
    },
  })

  return (
    <DropArea
      display="flex"
      flexDir="column"
      overflow="auto"
      message="拖动文件导入"
      {...props}
      onDropFiles={handleSelectFiles}
    >
      <Box
        pos="relative"
        display="flex"
        justifyContent="space-between"
        flexGrow={1}
        overflow="auto"
        border="1px solid"
        borderColor="neutrals.3"
      >
        {records.length ? (
          <FilesViewer files={records} flexGrow={1} />
        ) : (
          <Center flexGrow={1} flexDir="column">
            <DocumentArrowUpIcon width={50} height={50} />
            <Text fontSize="xx-large" mt={2}>
              拖动文件导入
            </Text>
            <Flex onClick={openFilePicker} gap={2} mt={2} alignItems="center">
              <Text color="gray.500" ml="-1em" flexShrink={0}>
                或
              </Text>
              <Button flexShrink={0} colorScheme="cyan" color="white">
                点击选择
              </Button>
            </Flex>
          </Center>
        )}
        {pending ? (
          <Center pos="absolute" inset={0} bgColor="rgba(255,255,255,0.7)" gap={2}>
            <Spinner size="md" emptyColor="gray.200" color="blue.500" thickness="6px" />
            <Text fontWeight="bold" fontSize="large">
              处理中...
            </Text>
          </Center>
        ) : null}
      </Box>
    </DropArea>
  )
}
