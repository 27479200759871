import { Box } from '@chakra-ui/react'
import { createFileRoute } from '@tanstack/react-router'

import { DataViewer } from '@/components/DataViewer'

function Index() {
  return (
    <Box
      m={3}
      w="100%"
      display="flex"
      flexDir="column"
      maxW={{ base: '100%', md: '1440px' }}
      mx="auto"
      flexGrow={1}
      overflow="auto"
      bg="white"
    >
      <DataViewer flexGrow={1} />
    </Box>
  )
}

export const Route = createFileRoute('/')({
  component: Index,
})
