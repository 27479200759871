import { Box, BoxProps } from '@chakra-ui/react'
import { intersection, union, uniq } from 'lodash-es'
import { useMemo } from 'react'

import { FileViewer } from '@/components/DataViewer/FileViewer'
import { FileRecord } from '@/components/DataViewer/types'
import { EMPTY_LIST } from '@/constants/misc'
import { useSettings } from '@/contexts/Settings'
import { useSelected } from '@/store/useSelected'

interface Props extends BoxProps {
  files: FileRecord[]
}

interface CodeRecord {
  codes: string[]
  name: string
  description?: string
  disableHighlight?: boolean
}

export function FilesViewer({ files: records, ...rest }: Props) {
  const { disableUnionColumn } = useSettings()
  const origins = useMemo(() => {
    return records.map((record) => {
      const codes = record.content.match(/\d{4}/g)
      return {
        codes: codes ? [...codes] : EMPTY_LIST,
        name: record.name,
      }
    })
  }, [records])

  const interList = useMemo(() => {
    if (origins.length < 2) return []
    return intersection(...origins.map((x) => x.codes))
  }, [origins])

  const unionList = useMemo(() => {
    if (origins.length < 2 || disableUnionColumn) return EMPTY_LIST
    return uniq(union(...origins.map((x) => x.codes)))
  }, [disableUnionColumn, origins])

  const interSet = useMemo(() => new Set(interList), [interList])
  const { selected } = useSelected()

  const allList: CodeRecord[] = useMemo(() => {
    if (origins.length < 2) return EMPTY_LIST
    const interRecord: CodeRecord = {
      codes: interList,
      name: '交集',
      description: '含有相同项',
      disableHighlight: true,
    }
    const lists: CodeRecord[] = [...origins, interRecord]
    if (selected.length) {
      lists.push({
        codes: selected,
        name: '已选',
        description: '已选择的',
        disableHighlight: true,
      })
    }
    if (!disableUnionColumn) {
      lists.push({
        codes: unionList,
        name: '并集',
        description: '所有项的集合',
        disableHighlight: true,
      })
    }
    return lists
  }, [origins, interList, selected, disableUnionColumn, unionList])

  return (
    <Box
      display="flex"
      flexDir={{
        base: 'column',
        md: 'row',
      }}
      overflow="auto"
      {...rest}
    >
      {allList.map((record) => {
        return (
          <FileViewer
            flexBasis={0}
            flexGrow={1}
            minHeight={{
              base: '25%',
              md: 'unset',
            }}
            borderLeft="1px solid"
            borderLeftColor="neutrals.3"
            _first={{
              borderLeft: 'none',
            }}
            overflow="auto"
            key={record.name}
            intersection={interSet}
            codes={record.codes}
            name={record.name}
            description={record.description}
            disableHighlight={record.disableHighlight}
          />
        )
      })}
    </Box>
  )
}
