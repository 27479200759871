import { noop } from 'lodash-es'
import { createContext, Dispatch, memo, PropsWithChildren, SetStateAction, useContext, useMemo } from 'react'
import { useLocalStorage } from 'react-use'

interface Options {
  disableInputTip: boolean
  setDisableInputTip: Dispatch<SetStateAction<boolean | undefined>>
  disableUnionColumn: boolean
  setDisableUnionColumn: Dispatch<SetStateAction<boolean | undefined>>
}

const INPUT_TIP_KEY = 'file-compare-input-disable-tooltip'
export const Settings = createContext<Options>({
  disableInputTip: false,
  setDisableInputTip: noop,
  disableUnionColumn: true,
  setDisableUnionColumn: noop,
})

export const SettingsProvider = memo(function SettingsProvider({ children }: PropsWithChildren) {
  const [disableTip = false, setDisableTip] = useLocalStorage<boolean>(INPUT_TIP_KEY)
  const [disableUnionColumn = true, setDisableUnionColumn] = useLocalStorage<boolean>(INPUT_TIP_KEY)
  const settings = useMemo(() => {
    return {
      disableInputTip: disableTip ?? false,
      setDisableInputTip: setDisableTip,
      disableUnionColumn,
      setDisableUnionColumn,
    }
  }, [disableTip, disableUnionColumn, setDisableTip, setDisableUnionColumn])

  return <Settings.Provider value={settings}>{children}</Settings.Provider>
})

export function useSettings() {
  return useContext(Settings)
}
