import '@/styles/index.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import ReactDOM from 'react-dom/client'

import { App } from '@/App'

dayjs.extend(utc)

const rootElement = document.getElementById('root')!
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(<App />)
}
