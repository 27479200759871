import { Center, Flex, type FlexProps, IconButton, Text } from '@chakra-ui/react'
import { AdjustmentsHorizontalIcon, ViewColumnsIcon } from '@heroicons/react/24/outline'
import { Link } from '@tanstack/react-router'
import { ComponentType } from 'react'

import { settingsModal } from '@/modals/SettingsModal'

export interface NavProps extends FlexProps {}

export const Nav: ComponentType<NavProps> = ({ ...props }) => {
  return (
    <Center
      w="100%"
      h="50px"
      borderBottom="1px solid"
      borderColor="neutrals.3"
      pos="relative"
      bgColor="blue.300"
      {...props}
    >
      <Flex p={5} align="center" w="100%" h="inherit" gap={2} maxW="1440px" pos="relative" color="white">
        <Link to="/">
          <ViewColumnsIcon width={32} height={32} />
        </Link>
        <Text fontSize="large" fontWeight="bold">
          文件比较工具
        </Text>
        <IconButton
          colorScheme="blue"
          ml="auto"
          size="xs"
          aria-label="打开设置"
          icon={<AdjustmentsHorizontalIcon />}
          onClick={() => {
            settingsModal.show()
          }}
        />
      </Flex>
    </Center>
  )
}
