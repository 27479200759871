import { Flex } from '@chakra-ui/react'
import { createRootRoute, Outlet } from '@tanstack/react-router'

import { Nav } from '@/components/Nav'
import { Modals } from '@/modals/index.tsx'

export const Route = createRootRoute({
  component: () => (
    <Flex direction="column" w="100%" minH="100svh" overflow="auto" h="100%" bg="gray.50">
      <Nav flexShrink={0} />
      <Outlet />
      <Modals />
    </Flex>
  ),
})
